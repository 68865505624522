import './style/App.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Tilty from 'react-tilty';

// Brand Imports
import logo from './content/brand/WHICSIC.png';

function App() {
  const [playerCount, setPlayerCount] = useState(0)
  useEffect(() => {
    // axios.get('http://server.whicsic.com:30120/players.json')
    // .then((res) => { setPlayerCount(res.data) })
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    fetch("http://server.whicsic.com:30120/players.json", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }, []);
  return (
    <>
    <div className="splash-bg">
      <Tilty max={20} reverse>
      <div className="splash-tilt">
        <div className="splash-logo">
          <img src={logo} alt="WHICSIC Logo"/>
        </div>
      </div>
      </Tilty>
    </div>
    </>
  );
}

export default App;
